import React, { useState } from 'react';
import './ContactUs.css';
import emailjs from 'emailjs-com';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        loanPartner: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send('service_sc85wtb', 'template_hyb655a', {
            to_name: "Students Choice",
            from_name: formData.name,
            from_email: formData.email,
            contact: formData.phone,
            loanPartner: formData.loanPartner || "Not specified",
            message: formData.message
        }, 'VbycJqRCfIVQ3Je28') // Replace with your EmailJS Public Key
        .then((response) => {
            alert("Message sent successfully!");
            setFormData({ name: '', email: '', phone: '', loanPartner: '', message: '' });
        })
        .catch((error) => {
            console.error("Error sending message:", error);
            alert("Failed to send message. Please try again.");
        });
    };

    return (
        <section className="contact-section">
            <div className="contact-header">
                <h2>Begin Your Journey | Secure Your Loan</h2>
                <p>Unlock your educational dreams today—apply now for our competitive education loans, available for both domestic and 
                    international studies, and take the first step towards a brighter future!</p>
            </div>
            <div className="contact-container">
                <div className="contact-image">
                    <img src={`${process.env.PUBLIC_URL}/image/contactus.png`} alt="Contact Us" />
                </div>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <label htmlFor="name">Name *</label>
                    <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required placeholder="Your Name" />

                    <label htmlFor="email">Email *</label>
                    <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required placeholder="Your Email" />

                    <label htmlFor="phone">Phone Number *</label>
                    <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required placeholder="Your Phone Number" />

                    <label htmlFor="loan-partner">Preferred Loan Partner</label>
                    <select id="loan-partner" name="loanPartner" value={formData.loanPartner} onChange={handleChange}>
                        <option value="">Select...</option>
                        <option value="ICICI">ICICI Bank (10.25% p.a)</option>
                        <option value="HDFC">HDFC Credila (10.00% p.a)</option>
                        <option value="Avanse">Avanse (10.9% p.a)</option>
                        <option value="UnionBank">Union Bank of India (9.5% p.a)</option>
                    </select>

                    <label htmlFor="message">Your Message *</label>
                    <textarea id="message" name="message" rows="5" value={formData.message} onChange={handleChange} required placeholder="Your Message"></textarea>

                    <button type="submit" className="submit-btn">Send Message</button>
                </form>
            </div>
        </section>
    );
};

export default ContactUs;
